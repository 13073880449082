import { createEvent } from '@/api/event'
import globals from '@/utils/globals'
import store from '@/store/index'

export default {
  saveRoute (route) {
    const isAuthenticated = store.getters['oauth/isAuthenticated']
    if (!isAuthenticated) return

    createEvent({
      location: `${globals.ApiUrl}${route}`,
      description: `Access to ${route}`
    })
      .then(() => {})
      .catch((err) => console.log(err))
  },

  getDayName (dayNumber) {
    switch (dayNumber) {
      case 0: return 'sunday'
      case 1: return 'monday'
      case 2: return 'tuesday'
      case 3: return 'wednesday'
      case 4: return 'thursday'
      case 5: return 'friday'
      case 6: return 'saturday'
      default: return 'not defined'
    }
  },

  getMonthName (monthNumber) {
    switch (monthNumber) {
      case 1: return 'january'
      case 2: return 'february'
      case 3: return 'march'
      case 4: return 'april'
      case 5: return 'may'
      case 6: return 'june'
      case 7: return 'july'
      case 8: return 'august'
      case 9: return 'september'
      case 10: return 'october'
      case 11: return 'november'
      case 12: return 'december'
      default: return 'not defined'
    }
  },

  get12HoursTime (date) {
    let hours = date.getHours()
    const amPm = hours >= 12 ? 'pm' : 'am'
    const minute = date.getMinutes()
    hours = (hours % 12) || 12
    return `${hours.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${amPm}`
  },

  getHoursDiffDates (initDate, endDate) {
    return Math.abs(endDate.getTime() - initDate.getTime()) / 36e5
  },

  formatDateDDMMYYServer (fulldate) {
    if (!fulldate) {
      return ''
    }
    const fulldateObj = new Date(fulldate)
    return `${this.padZeros(fulldateObj.getDate())}/${this.padZeros(fulldateObj.getMonth() + 1)}/${fulldateObj.getFullYear().toString().substr(2, 2)}`
  },

  formatDateDDMMYY (fulldate) {
    if (!fulldate) {
      return ''
    }
    const [date] = new Date(fulldate).toLocaleString().split(', ')
    const [month, day, year] = date.split('/')
    return `${this.padZeros(day)}/${this.padZeros(month)}/${year.toString().substr(2, 2)}`
  },

  formatDateDDMMYYLocal (fulldate) {
    if (!fulldate) {
      return ''
    }
    const fulldateObj = new Date(fulldate)
    const [date] = new Date(fulldateObj.setMinutes(fulldateObj.getMinutes() + fulldateObj.getTimezoneOffset())).toLocaleString().split(', ')
    const [month, day, year] = date.split('/')
    return `${this.padZeros(day)}/${this.padZeros(month)}/${year.toString().substr(2, 2)}`
  },

  formatDateYYYYMMDD (date) {
    const day = this.padZeros(date.getDate())
    const month = this.padZeros(date.getMonth() + 1)
    const year = date.getFullYear().toString()
    return `${year}-${month}-${day}`
  },

  capitalize (str) {
    const lower = str.toLowerCase()
    return str.charAt(0).toUpperCase() + lower.slice(1)
  },

  padZeros (str, length = 2) {
    return str.toString().padStart(length, '0')
  },

  sortByDate (a, b) {
    return new Date(b.lastMessage).getTime() - new Date(a.lastMessage).getTime()
  },

  validateFLNameFormat (value) {
    return /^(?!^\d+$)[A-Za-zÀ-ÖØ-öø-ÿ ]+$/.test(value)
  },

  validateApartmentAndBuildingFormat (value) {
    return /[A-Za-zÀ-ÖØ-öø-ÿ\d ]+$/.test(value)
  },

  getAvatar (url) {
    // const imgExists = this.imageExists(url)
    return url || globals.defaultAvatar
  },

  formatNumber (number, maximumFractionDigits = 2) {
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits })
    return formatter.format(number)
  },

  truncateText (msg, length) {
    return msg.length > length ? msg.substr(0, length) + '...' : msg
  }

  // imageExists (imageUrl) {
  //   // try {
  //   //   const http = new XMLHttpRequest()
  //   //   http.open('HEAD', imageUrl, false)
  //   //   http.send()
  //   //   return http.status !== 404
  //   // } catch (e) {
  //   //   console.log('error here -> ', e)
  //   // }
  // }
}
