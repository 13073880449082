<template>
  <SuiModal basic v-model="dialog" style="overflow-y: hidden">
    <SuiModalContent style="overflow-y: hidden; height: 600px; display: flex !important; align-items: center">
        <div class="reservation_export_container">
            <div class="close_modal_x">
                <a @click="close()">
                    <i style="text-decoration:none !important; cursor: pointer;font-size: 25px"
                        class="times icon general-blue-color"
                    ></i>
                </a>
            </div>
            <SuiModalDescription>
                  <div class="description_container">

                    <p class="text" style="margin-top: 25px; text-align: center">
                      {{$t('pay.download1')}} {{chargeAccountId}}{{$t('pay.download2')}} {{activeResidential.name}}.
                    </p>
                    <!-- <p class="text" style="margin-top: 25px; text-align: center">
                      Vas a descargar el reporte del Mes {{getMonthName(month)}}, Año {{year}} del edificio/Conjunto {{activeResidential.name}}.
                    </p> -->

                    <!-- Export button -->
                    <div class="btn_container">
                      <!-- <div> -->
                        <Button
                            :appearance="classBtnObject"
                            :text="$t('pay.download')"
                            color="primary"
                            @clicked="exportPayments"
                        />
                      <!-- </div> -->
                    </div>
                  </div>
            </SuiModalDescription>
        </div>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import { es } from 'date-fns/locale'
import Button from '@/components/form/Button'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators/dist/raw.esm'
import { mapActions, mapGetters } from 'vuex'
import { chargeAccountReportPayment } from '@/api/payment'
import helpersUtils from '../../utils/helpers'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'ChargeAccountsExportModal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    month: {
      type: Number,
      required: true
    },
    year: {
      type: String,
      required: true
    },
    chargeAccountId: {
      type: Number,
      required: true
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Button
  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      caExportform: {
        initialDate: null,
        finishDate: null
      },
      // excelReportColumns: [
      //   'Empresa',
      //   'Dirección',
      //   'Teléfono',
      //   'NIT',
      //   'Correo electrónico',
      //   'IP',
      //   'Edificio',
      //   'Apartamento',
      //   'Nro. Documento',
      //   'Número pago',
      //   'Medio de pago',
      //   'Fecha del pago',
      //   'Ticket ID',
      //   'Tipo de Usuario',
      //   'Estado transacción',
      //   'Concepto',
      //   'Ciclo transacción',
      //   'Banco',
      //   'Cod. servicio',
      //   'Total',
      //   'IVA'
      // ].map(function (colName) {
      excelReportColumns: [
        this.$t('pay.company'),
        this.$t('building.building'),
        this.$t('building.apartment'),
        this.$t('pay.id'),
        this.$t('pay.paymentMethod'),
        this.$t('pay.paymentDate'),
        this.$t('pay.ticketId'),
        this.$t('pay.status'),
        this.$t('pay.bank'),
        this.$t('pay.manteinment')
      ].map(function (colName) {
        return {
          v: colName,
          t: 's',
          s: {
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' }
            },
            fill: { fgColor: { rgb: '436F98' } },
            alignment: {
              vertical: 'center',
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } }
            }
          }
        }
      }),
      // columnLetters: ['B', 'C', 'D', 'E', 'F']
      columnLetters: []
    }
  },
  validations () {
    return {
      caExportform: {
        initialDate: {
          required: helpers.withMessage(this.$t('pay.incomingDate'), (initialDateValue) => Boolean(initialDateValue))
        },
        finishDate: {
          required: helpers.withMessage(this.$t('pay.finishDate'), (finishDateValue) => Boolean(finishDateValue))
        }
      }
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    dialog () {
      return this.show
    },
    es () {
      return es
    },
    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },
    residential () {
      return this.$store.getters['residential/activeResidential']
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    close () {
      this.caExportform.initialDate = null
      this.caExportform.finishDate = null
      this.$emit('close')
      this.v$.$reset()
    },
    getMonthName (month) {
      return helpersUtils.capitalize(this.$t(`months.${helpersUtils.getMonthName(month)}`))
    },
    getExcelFormattedStylish (data) {
      const paymentDate = new Date(data.paymentDate)
      const formatedPaymentDate = `${helpersUtils.formatDateDDMMYYServer(paymentDate)} ${helpersUtils.get12HoursTime(paymentDate)}`

      const cellStyleObject = {
        alignment: {
          vertical: 'center',
          horizontal: 'center'
        },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } }
        }
      }
      // return [
      //   { v: 'ZIBI', s: cellStyleObject },
      //   { v: ' CR 7 B BIS NO. 126 36 Bogotá', s: cellStyleObject },
      //   { v: '3164186845', s: cellStyleObject },
      //   { v: '9016464502', s: cellStyleObject },
      //   { v: data.email ?? '', s: cellStyleObject },
      //   { v: data.ipAddress ?? '', s: cellStyleObject },
      //   { v: this.residential.name ?? '', s: cellStyleObject },
      //   { v: data.apartment ?? '', s: cellStyleObject },
      //   { v: data.document ?? '', s: cellStyleObject },
      //   { v: data.id ?? '', s: cellStyleObject },
      //   { v: data.paymentMethod ?? '', s: cellStyleObject },
      //   { v: formatedPaymentDate ?? '', s: cellStyleObject },
      //   { v: data.transactionId ?? '', s: cellStyleObject },
      //   { v: '', s: cellStyleObject },
      //   { v: data.status === 'PAID' ? 'Pago exitoso' : 'Pago rechazado', s: cellStyleObject },
      //   { v: data.concept ?? '', s: cellStyleObject },
      //   { v: '', s: cellStyleObject },
      //   { v: data.paymentDetail ?? '', s: cellStyleObject },
      //   { v: '', s: cellStyleObject },
      //   { v: data.total ?? '', t: 'n', s: { ...cellStyleObject, numFmt: '#,###.00' } },
      //   { v: data.iva ?? '', t: 'n', s: { ...cellStyleObject, numFmt: '#,###.00' } }
      // ]
      return [
        { v: 'LIZI', s: cellStyleObject },
        { v: this.residential.name ?? '', s: cellStyleObject },
        { v: data.apartment ?? '', s: cellStyleObject },
        { v: data.document ?? '', s: cellStyleObject },
        { v: data.paymentMethod ?? '', s: cellStyleObject },
        { v: formatedPaymentDate ?? '', s: cellStyleObject },
        { v: data.transactionId ?? '', s: cellStyleObject },
        { v: data.status === 'PAID' ? this.$t('pay.succesfulPayment') : this.$t('pay.rejectedPayment'), s: cellStyleObject },
        { v: data.paymentDetail ?? '', s: cellStyleObject },
        { v: data.total ?? '', t: 'n', s: { ...cellStyleObject, numFmt: '#,###.00' } }
      ]
    },
    createReport (data) {
      const paidData = data
        .filter(row => row.status === 'PAID')
        .map(row => this.getExcelFormattedStylish(row))

      const declinedData = data
        .filter(row => row.status === 'PAYMENT_DECLINED' || row.status === 'PAYMENT_REJECTED')
        .map(row => this.getExcelFormattedStylish(row))

      const reportWorkBook = XLSX.utils.book_new()
      const filename = `${this.residential.name}_Reporte_Pagos_${this.getMonthName(this.month)}`

      // Create worksheet with reservation data & Headers
      const colsFormatted = this.excelReportColumns

      const paidContent = [colsFormatted, ...paidData]
      const declinedContent = [colsFormatted, ...declinedData]
      const paidWorkSheet = XLSX.utils.aoa_to_sheet(paidContent)
      const declinedWorkSheet = XLSX.utils.aoa_to_sheet(declinedContent)

      const paidMaxWidth = paidContent.reduce((w, row) => Math.max(w, row.reduce((l, objValue) => Math.max(l, objValue.v.length), 10)), 10)
      const declinedMaxWidth = declinedContent.reduce((w, row) => Math.max(w, row.reduce((l, objValue) => Math.max(l, objValue.v.length), 10)), 10)

      // console.log('Generado del data de Columnas')
      // Fix maximum width for every column
      paidWorkSheet['!cols'] = this.columnLetters.map(function () { return { wch: paidMaxWidth } })
      declinedWorkSheet['!cols'] = this.columnLetters.map(function () { return { wch: declinedMaxWidth } })

      // console.log('Generado de hojas del archivo Excel')
      // Append worksheets to Workbook
      XLSX.utils.book_append_sheet(reportWorkBook, paidWorkSheet, this.$t('pay.succesfulPayments'))
      XLSX.utils.book_append_sheet(reportWorkBook, declinedWorkSheet, this.$t('pay.rejectedPayments'))

      // console.log('Generado del archivo Excel')
      // Write Excel file to browser
      XLSX.writeFile(reportWorkBook, `${filename}.xlsx`)
    },

    async exportPayments () {
      this.setLoading(true)

      const data = {
        residentialId: this.residential.residentialId,
        chargeAccountId: this.chargeAccountId
      }
      chargeAccountReportPayment(data)
        .then((r) => {
          const data = r.data
          data.forEach(chargeAccInfo => {
            chargeAccInfo.paymentDate = chargeAccInfo.paymentDate.replace('Z', '')
          })
          // console.log('report response', data)
          if (data.length === 0) {
            this.$swal(`${this.$t('pay.notExist')} ${this.chargeAccountId}`, '', 'info')
          } else {
            this.createReport(data)
            this.$swal(this.$t('pay.successfulExport'), '', 'success')
            this.close()
          }
        }).catch((error) => {
          console.error(this.$t('pay.error'), error)
          this.$swal(this.$t('pay.errorMessage'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
    }
  }

}
</script>

<style scoped>
    h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      margin: 0;
      padding: 0;
      padding-left: 50px !important;
      max-width: inherit;
    }
    .text {
      font-size: 16px;
      color: #323232;
      margin-right: 20px
    }
    .reservation_export_container {
        background-color: #fff;
        width: 70%;
        height: 200px;
        border-radius: 20px;
    }
    .description_container{
        padding: 50px !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        height: 100%;
        margin-top: -15px !important;
    }
    .range_date_container {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .close_modal_x {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        padding-top: 15px;
    }
    .date {
        width: 50%;
        display: flex;
        align-items: center
    }
    .btn_container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
</style>
