<template>
    <div class="uploader_field" style="height: 65%" :id="`inputFiles-${id}`">
        <div
          class="upload_btn_cover"
          style="border-radius: 4px !important; margin-right: 10px; display: flex; align-items: center; position: relative"
          :style="`width: ${sizeOfButton['width']}; height:${sizeOfButton['height']}`"
        >
            <label
              style="width: 100%; text-align: center; display: flex; position: absolute; justify-content: center"
              for="file" id="labelFiles"
            >
              <input
                @change="onFileChange"
                type="file"
                :style="`width: ${sizeOfButton['width']}; height:${sizeOfButton['height']}`"
                ref="file"
                class="inputfile"
                multiple
              />
              <span>  {{  $t('upload_file') }}</span>
            </label>
        </div>
        <div v-if="help"
          style="color: #436f98"
          class="upper-left-label"
          :data-tooltip="popUpHelpText"
          data-position="bottom left"
          data-variation="small wide"
        >
          <i class="question circle outline icon"></i>
        </div>
          <div
            v-for="(fileSelected, idx) in selectedFiles"
            :key="fileSelected"
            :id="`names-${id}`"
            style="display:flex"
          >
              {{nameOfFile(fileSelected)}}
              <!-- checks.get(fileSelected) -->
              <!-- :style="`color: ${ checks[idx] ? 'rgb(44, 195, 48)' : '#ff0202'}`" -->
              <div
                style="color: #ff0202"
                class="upper-left-label"
                :data-tooltip="$t('delete_file')"
                data-position="top center"
                data-variation="small"
              >
                <!-- :class="`${ checks[idx] ? 'check' : 'times'} icon`" -->
                <!-- @mouseover="changeCheckStatus(idx, fileSelected)" -->
                <!-- @mouseleave="changeCheckStatus(idx, fileSelected)" -->
                  <i v-if="fileSelected"
                    @click="deleteSelectedFile(fileSelected)"
                    :id="`delete-${idx}`"
                    class="times icon"
                    style="cursor: pointer; margin-left: 5px"
                  >
                  </i>
              </div>
          </div>
    </div>
</template>

<script>
export default {
  name: 'FileUploader',
  props: {
    help: {
      type: Boolean,
      required: false,
      default: true
    },
    id: {
      type: String,
      required: false
    },
    modelValue: {
      type: Array,
      required: true
    },
    maxNumFiles: {
      type: Number,
      required: true
    },
    maxFileSizeInMB: {
      type: Number,
      required: true
    },
    allowedFileTypes: {
      type: Array,
      required: true
    },
    sizeOfButton: {
      type: Object,
      required: false,
      default () {
        return {
          height: '65%',
          width: '25%'
        }
      }
    },
    maxLengthPerName: {
      type: Number,
      required: false,
      default: 15
    },
    helpText: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      filesSelected: [],
      newFiles: false
      // checks: new WeakMap()
      // checks: new Array(this.modelValue.length).fill(true)
    }
  },

  emits: ['update-modelValue'],
  watch: {
    modelValue: {
      immediate: true,
      handler: function (files) {
        this.selectedFiles = [...files]
        // this.checks = new Array(this.selectedFiles.length).fill(true)
      }
    }
  },
  computed: {
    popUpHelpText () {
      return this.helpText ? this.helpText : `${this.$t('files_supported')} ${this.allowedFileTypes.join(', ')}.  ${this.$t('max_weight')} ${this.maxFileSizeInMB}MB ${this.maxNumFiles > 1 ? `${this.$t('per_file')}` : ''}. ${this.$t('size')} 1080x1080px`
    },
    selectedFiles: {
      get () {
        return this.modelValue
      },
      set (newVal) {
        // console.log('new val ', newVal)
        this.filesSelected = newVal
      }
    }
  },
  methods: {
    // changeCheckStatus (idx, fileSelected) {
    //   // this.icon_checks = [idx, !this.icon_checks[idx]]
    //   this.checks[idx] = !this.checks[idx]
    //   console.log('changing checks are: ', this.checks)
    // },

    byteSizeToMBSize (fileSizeInB) {
      const sizeInMB = fileSizeInB / 10e5
      return sizeInMB
    },

    getFileType (file) {
      const fileType = file.type.split('/')[1]
      return fileType
    },

    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      // this.newFiles = true
      files = [...files]

      // Checking number of files
      if (files.length > this.maxNumFiles) {
        this.$swal(`${this.maxNumFiles} ${this.$t('files.max')}`, '', 'error')
        return
      } else if (files.some(file => this.byteSizeToMBSize(file.size) > this.maxFileSizeInMB)) { // Checking size of files
        // const bigFiles = files.filter(file => this.byteSizeToMBSize(file.size) > this.maxFileSizeInMB)
        this.$swal(`${this.$t('files.maxSize')} ${this.maxFileSizeInMB}MB`, '', 'error')
        return
      } else if (
        files
          .some(
            file => {
              const nameOfFile = file.name
              const fileExtension = nameOfFile.split('.').pop()

              return !(this.allowedFileTypes.includes(this.getFileType(file))) &&
                     !(this.allowedFileTypes.includes(fileExtension))
            }
          )
      ) {
        this.$swal(`${this.$t('files.formats')} ${this.allowedFileTypes.join(', ')}`, '', 'error')
        return
      }

      this.$emit('update-modelValue', files)
      this.$refs.file.value = null
    },

    getFullFileName (file) {
      if (file.name) {
        return file.name
      } else if (!file.name) { // this will change a bit when fetching data //////////////////////////////////////-------
        const splitted = file.split('/')
        return splitted[splitted.length - 1]
      }
      return ''
    },
    nameOfFile (file) {
      let fileName = file ? this.getFullFileName(file) : '' // getting complete name of file

      // const maxLengthPerName = 20
      // checking length of name and trimming it if necessary
      if (fileName.length > this.maxLengthPerName) {
        const [name, format] = fileName.split('.')
        fileName = name.substring(0, this.maxLengthPerName - 1 - format.length) + '...' + format
      }

      return fileName
    },

    deleteSelectedFile (fileSelectedToDelete) {
      const idxOfFileToDelete = this.selectedFiles.indexOf(fileSelectedToDelete)
      this.selectedFiles.splice(idxOfFileToDelete, 1)

      this.$emit('update-modelValue', this.selectedFiles)
    }
  }
}
</script>

<style scoped>

.uploader_field {
  display: flex;
  width:100%;
  height: 32%;
  color: black;
  align-items: center
}

.upload_btn_cover {
  background-color: #CACBCE;
  transition: background-color .5s ease !important;
}

.upload_btn_cover:hover {
 background-color: #9ea1ac !important;
}

.inputfile {
  /* height: 0.1px !important;*/
  /*width: 0.1px !important; */
  opacity: 0;
  /* overflow: hidden;*/
  position: absolute;
  z-index: 5;
  cursor: pointer !important;
  padding: 0;
  margin: 0;
}
input[type=file]::-webkit-file-upload-button{
  cursor: pointer;
}

.inputfile + label {
    display: inline-block;
    cursor: pointer;
    color: black;
    font-weight: bold;
    font-size: 14px !important;
}
.inputfile > span {
    display: inline-block !important;
    cursor: pointer;
    color: black;
    font-weight: bold !important;
    font-size: 14px !important;
}

[data-position="right center"][data-tooltip]:before {
  background-color: #D3F7F5 !important;
  border: 0px;
  box-shadow: -1px 1px 0 0 #d3f7f5;
  margin-left: -0.2rem;
}

[data-position="top center"][data-tooltip]:before {
  background-color: #D3F7F5 !important;
  border: 0px;
  box-shadow: -1px 1px 0 0 #d3f7f5;
  margin-left: -0.2rem;
}

[data-position="bottom left"][data-tooltip]:before {
  background-color: #D3F7F5 !important;
  border: 0px;
  box-shadow: -1px 1px 0 0 #d3f7f5;
  margin-left: -0.2rem;
  font-size: 0.897rem;
}

</style>
