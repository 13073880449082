<template>
  <div class="row" style="border-bottom: 1px solid rgba(0,0,0,0.1); padding-bottom: 7px; margin-right: 5px">
    <div class="col-md-11">
      <div class="ui image label header input">
        <img src="@/assets/img/icons/building_active.png" class="cursor-pointer" style="border-radius: 0px;"
          @click="goToAdmin">
        <span v-if="!isEditingTitle" style="font-size: 17px">{{ activeResidential.name }}</span>
        <input v-else v-model="titleEdition" class="input-building tiny" style="padding: 4px; font-size: 16px"
          @blur="cancel('title')" @keydown.esc="cancel('title')" @keydown.enter="save('title')">
        <a v-if="!isEditingTitle" href="#" @click="edit('title')"><i class="edit small outline icon"
            style="margin-left: 5px"></i></a>

        <span v-if="!isEditingAddress" style="font-size: 14px; margin-left: 20px">{{ activeResidential.address }}</span>
        <VueGoogleAutocomplete v-else id="address-google" classname="input-address"
          placeholder="Ingresa y selecciona una dirección" type="(address)" ref="address"
          :country="['co', 've', 'mx', 'cw','es']"
          style="margin-left: 20px; padding: 4px; font-size: 16px" @blur="cancel('address')"
          @placechanged="getAddressData">
        </VueGoogleAutocomplete>
        <!-- <input
            v-else
            v-model="addressEdition"
            class="input-building"
            style="margin-left: 20px; padding: 1px; padding-left: 5px; font-size: 16px"
            @blur="cancel('address')"
            @keydown.esc="cancel('address')"
            @keydown.enter="save('address')"
          > -->
        <a v-if="!isEditingAddress" href="#" @click="edit('address')"><i class="edit small outline icon"
            style="margin-left: 5px"></i></a>
      </div>
    </div>
    <!-- <div class="col-md-1 text-right" style="padding-right: 0px">
      <a href="javascript:;" @click="showQrModal = true">
        <QrcodeVue :value="code" :size="30" render-as="svg" level="H" foreground="#436F98" />
      </a>
    </div> -->
  </div>

  <QRPreviewModal :show="isShowingQrModal" @close="closeQrModal" />
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
// import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex'
import QRPreviewModal from '../modals/QRPreviewModal'

export default {
  name: 'HeaderResidential',
  components: {
    VueGoogleAutocomplete,
    // QrcodeVue,
    QRPreviewModal
  },
  data () {
    return {
      titleEdition: '',
      addressEdition: '',
      coordinates: { latitude: 0, longitude: 0 },
      isEditingTitle: false,
      isEditingAddress: false,
      showQrModal: false
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    title () {
      return this.name
    },
    isShowingQrModal () {
      return this.showQrModal
    },
    code () {
      return this.activeResidential.qrLink ?? ''
    }
  },
  methods: {
    edit (field) {
      if (field === 'title') {
        this.isEditingTitle = true
        this.titleEdition = this.activeResidential.name
        this.$refs.address.update(this.titleEdition)
      } else if (field === 'address') {
        this.isEditingAddress = true
        this.addressEdition = this.activeResidential.address
      }
    },

    cancel (field) {
      if (field === 'title') {
        this.isEditingTitle = false
        this.titleEdition = ''
      } else if (field === 'address') {
        const address = document.getElementById('address-google').value
        if (address === '') {
          this.isEditingAddress = false
          this.addressEdition = ''
        }
      }
    },

    save (field) {
      if (field === 'title') {
        if (this.titleEdition === '') {
          return this.$swal('Debes ingresar el nombre de la comunidad', '', 'warning')
        }
        const isSaved = this.sendDataToSave({ name: this.titleEdition })
        if (isSaved) {
          this.isEditingTitle = false
        } else {
          this.$swal('La comunidad no se pudo modificar, revisa los datos o contáctate con el administrador', '', 'error')
        }
      }
    },

    sendDataToSave (data) {
      try {
        this.$store.dispatch('residential/updateResidential', { id: this.activeResidential.residentialId, data })
        return true
      } catch (error) {
        return false
      }
    },

    getAddressData (addressData) {
      const { latitude, longitude } = addressData
      this.address = document.getElementById('address-google').value
      this.coordinates.latitude = latitude
      this.coordinates.longitude = longitude
      this.$refs.address.blur()

      const addressArray = this.address.split(', ')
      const address = addressArray[0]
      const city = addressArray[1]
      const state = (addressArray.length === 3) ? city : addressArray[2]
      const country = (addressArray.length === 3) ? addressArray[2] : addressArray[3]

      const isSaved = this.sendDataToSave({ address, city, state, country, latitude, longitude })
      if (isSaved) {
        this.isEditingAddress = false
      } else {
        this.$swal('La comunidad no se pudo modificar, revisa los datos o contáctate con el administrador', '', 'error')
      }
    },

    goToAdmin () {
      this.$router.push({ path: '/residential' })
    },

    closeQrModal () {
      this.showQrModal = false
    }
  }
}
</script>

<style scoped>
.header {
  background-color: transparent !important
}

.input-address {
  width: 300px;
}

.input-building:focus {
  outline: none;
}

.form-control {
  display: inline !important;
}
</style>
