<template>
  <SuiModal basic v-model="dialog" class="fullscreen" id="modal-excel">
    <SuiModalContent >
      <div class="modal-container">
        <SuiModalDescription>
          <div class="align-items-center div-form ">
            <h2
                class="general-blue-color"
                style="margin-top: 16px; margin-bottom: 30px; font-size: 17px"
              >
              {{$t('pay.creatingAccountFor')}} {{ activeResidential.name }}.
            </h2>

            <p>
              {{$t('pay.validateData')}} <a href="#">{{$t('pay.clickHere')}}</a>.
            </p>

            <div class="row">
              <div class="col-md-2">{{$t('pay.year')}}: <strong>{{form.year}}</strong></div>
              <div class="col-md-2">{{$t('pay.month')}}: <strong>{{form.month}}</strong></div>
            </div>

            <div class="row">
              <div class="col-md-2">{{$t('pay.discount')}}: <strong>{{form.discount}}</strong></div>
              <div class="col-md-2">{{$t('pay.dateExpirationDiscount')}}: <strong>{{getVisualFormattedDate(dataAccountForm.expirationDiscount)}}</strong></div>
              <div class="col-md-2">{{$t('pay.expirationDate')}}: <strong>{{getVisualFormattedDate(dataAccountForm.expirationFee)}}</strong></div>
            </div>
          </div>

          <div class="row container-table" v-if="showPreview">
            <table class="ui blue unstackable selectable celled table" style="cursor: default !important; color: #808080">
              <thead>
                <tr>
                  <th v-for="name in tableData.header" :key="name" class="text-center">{{name}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in tableData.data" :key="row">
                  <td
                    v-for="column in Object.keys(row)" :key="column"
                    class="text-center"
                  >{{row[column]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="error-container" v-if="errors.length">
            <div v-if="errors.length">
              <span class="ui red text"><strong>{{ errors.length }}</strong> {{$t('pay.duplicates')}}</span>
            </div>

            <div class="mt-1 mb-4">
              <div v-if="errors.length" class="ui red message" style="overflow-y: auto; max-height: 380px">
                <div class="header">{{$t('pay.unitsDuplicated')}}: </div>
                <ul class="list">
                  <li
                    v-for="error in errors"
                    :key="error.index"
                  >
                    {{$t('pay.row')}}: <strong>{{ error.index }}</strong>,
                    {{$t('pay.unit')}}:
                    <strong>
                      <span>{{ error.apartment }}</span>
                    </strong>.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="error-container" v-if="errorsAmount.length">
            <div v-if="errorsAmount.length">
              <span class="ui red text"><strong>{{ errorsAmount.length }}</strong> {{$t('pay.wrongAccounts')}}</span>
            </div>

            <div class="mt-1 mb-4">
              <div v-if="errorsAmount.length" class="ui red message" style="overflow-y: auto; max-height: 380px">
                <div class="header">{{$t('pay.unitsWithWrongAccounts')}}: </div>
                <ul class="list">
                  <li
                    v-for="error in errorsAmount"
                    :key="error.index"
                  >
                    {{$t('pay.row')}}: <strong>{{ error.index }}</strong>,
                    {{$t('pay.unit')}}:
                    <strong>
                      <span>{{ error.apartment }}</span>
                    </strong>.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="text-center mb-4" >
            <Button
              color="third-color"
              :text="$t('buttons.cancel')"
              class="d-inline"
              :appearance="{ 'margin-right': '8px', height: '28px' }"
              @clicked="close" />
            <Button
              :text="$t('buttons.createPayAccount')"
              @clicked="saveChargeAccount"
              color="primary"
              class="d-inline"
            />
          </div>
        </SuiModalDescription>
      </div>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription
} from 'vue-fomantic-ui'
import * as XLSX from 'xlsx'
import Button from '../../components/form/Button'
import helpers from '../../utils/helpers'

export default {
  name: 'ChargeAccountExcelReview',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Button
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    dataAccountForm: Object
  },
  data () {
    return {
      errors: [],
      errorsAmount: [],
      showPreview: true,
      tableData: {
        header: [],
        data: []
      }
    }
  },
  watch: {
    show (isShown) {
      if (isShown === true) {
        this.loadDataTable()
      }
    }
  },
  created () {
    setTimeout(() => {
      document.getElementsByClassName('modal')[2].style.width = '97%'
    }, 1000)
  },
  computed: {
    ...mapGetters('chargeAccount', ['chargeAccountTypes']),
    ...mapGetters('residential', ['activeResidential']),
    dialog () {
      return this.show
    },
    form () {
      return {
        year: this.dataAccountForm.year.text,
        month: helpers.capitalize(this.dataAccountForm.month.text),
        discount: this.dataAccountForm.discount,
        // expirationDiscount: helpers.formatDateDDMMYY(this.dataAccountForm.expirationDiscount),
        // expirationFee: helpers.formatDateDDMMYY(this.dataAccountForm.expirationFee)
        expirationDiscount: this.dataAccountForm.expirationDiscount,
        expirationFee: this.dataAccountForm.expirationFee
      }
    }
  },
  methods: {
    ...mapActions('chargeAccount', ['createChargeAccount']),
    loadDataTable () {
      const file = this.dataAccountForm.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const bstr = e.target.result
          const workbook = XLSX.read(bstr, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          // const xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })
          const columns = [
            this.$t('pay.unit'),
            ...this.chargeAccountTypes.map(type => type.name)
          ]

          const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1, defval: '' })
          const data = jsonData.map((row) => Object.keys(row).map((column) => row[column]))
          const countData = data.length - 1
          const xlData = []
          for (let i = 1; i <= countData; i++) {
            const content = []
            const row = data[i]
            const apartment = row[0]
            content.push(apartment)

            for (const type of this.chargeAccountTypes) {
              const dynamicColumn = this.dataAccountForm.dynamicFields.find(
                (field) => Number(field.id) === type.id
              )
              if (!(dynamicColumn && dynamicColumn.value.trim() === '')) {
                const column = dynamicColumn ? dynamicColumn.value.trim().toUpperCase() : type.columnInFile
                const colNumber = XLSX.utils.decode_col(column)
                const amount = Number(row[colNumber])
                content.push(isNaN(amount) ? 0 : helpers.formatNumber(amount, 2))
              } else {
                content.push(0)
              }
            }
            xlData.push(content)
          }

          this.tableData = { header: columns, data: xlData }
        }
        reader.readAsBinaryString(file)
      }
    },

    async saveChargeAccount () {
      const file = this.dataAccountForm.files[0]
      const now = new Date(Date.now())
      const formData = new FormData()
      formData.append('year', this.dataAccountForm.year.value)
      formData.append('month', this.dataAccountForm.month.value)
      formData.append('expirationFee', this.getFormattedDate(this.dataAccountForm.expirationFee))
      formData.append('expirationDiscount', this.getFormattedDate(this.dataAccountForm.expirationDiscount))
      formData.append('discount', this.dataAccountForm.discount)
      formData.append('dynamicFields', JSON.stringify(this.dataAccountForm.dynamicFields))
      formData.append('residentialId', this.activeResidential.residentialId)
      formData.append('timezoneOffset', now.getTimezoneOffset())
      formData.append('file', file)

      // if (formData) {
      //   for (const [key, value] of formData.entries()) {
      //     console.log(key, value)
      //   }
      // }

      this.createChargeAccount(formData).then(() => {
        this.$swal(
          this.$t('pay.created'),
          `${this.$t('pay.month')}: ${this.dataAccountForm.month.text} - ${this.$t('pay.year')}: ${this.dataAccountForm.year.text}`,
          'success'
        )
        this.$emit('onSave')
        this.close()
      }).catch((err) => {
        const { message, error } = err.response.data
        console.log(err)
        if (error === 'Una cuenta de cobro no puede tener unidades o conjuntos repetidos.') {
          this.showPreview = false
          this.$swal(
            this.$t('errors.pay.repeatedUnits'),
            '',
            'warning'
          )
          this.errors = message
          return
        }
        if (error.includes('El monto de pago no puede ser menor a')) {
          // console.log('Error de monto')
          this.showPreview = false
          this.$swal(
            this.$t('errors.pay.paymentLessThan'),
            '',
            'warning'
          )
          this.errorsAmount = message
          return
        }
        const errorMessage = message || this.$t('errors.pay.error')
        this.$swal(
          errorMessage,
          '',
          'warning'
        )
      })
    },

    close () {
      this.$emit('close')
      this.errors = []
      this.errorsAmount = []
      this.showPreview = true
    },

    getFormattedDate (date) {
      // `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} 23:59:00`
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} 23:59:00`
    },
    getVisualFormattedDate (date) {
      if (date) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return `${day < 9 ? `0${day}` : `${day}`}/${month < 9 ? `0${month}` : `${month}`}/${year}`
      }
      return ''
    }
  }
}
</script>

<style scoped>
  @media only screen and (min-width: 768px) {
    .ui.modal {
      width: 90% !important;
      margin: 0 !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .ui.modal {
      width: 90% !important;
      margin: 0;
    }

    .ui.modal:not(.fullscreen) {
      width: 90% !important;
      margin: 0;
    }
  }

  .modal-container {
    background-color: #fff;
    color: black;
    width: 80%;
    border-radius: 20px;
    padding: 20px;
    padding-top: 10px;
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    z-index: 99;
  }

  .container-table {
    overflow: auto;
    max-height: 40vh;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .headerTable {
    background: #f9fafb;
    color: rgba(0,0,0,.87);
    font-weight: 700;
  }

  .error-container {
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
