<template>
  <SuiModal basic v-model="dialog">
    <SuiModalContent >
      <div class="modal-container">
        <a @click="close" class="close-icon" style="left: 96%">
            <i class="times icon general-blue-color"></i>
          </a>
          <SuiModalDescription>
            <div class="align-items-center div-form ">
              <h2
                class="general-blue-color"
                style="margin-top: -16px; margin-bottom: 30px; font-size: 17px"
              >{{$t('pay.create')}}</h2>

              <div class="ui form">
                <form autocomplete="off">
                  <div class="row mb-1">
                    <div class="col-md-2 pt-2"><label>{{$t('pay.year')}}</label></div>
                    <div class="col-md-4 ui input">
                      <Select
                        v-model="accountForm.year"
                        :placeholder="$t('pay.year')"
                        :options="years"
                        :selection="true"
                        :fluid="false"
                      />
                    </div>
                    <div class="col-md-2 pt-2"><label>{{$t('pay.month')}}</label></div>
                    <div class="col-md-4 ui input">
                      <Select
                        v-model="accountForm.month"
                        :placeholder="$t('pay.month')"
                        :options="months"
                        :selection="true"
                        :fluid="false"
                      />
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-2 pt-2">{{$t('pay.discount')}}</div>
                    <div class="col-md-4">
                      <input
                        placeholder="10"
                        type="number"
                        :minlength="accountFormValidation.minLengthDiscount"
                        :maxlength="accountFormValidation.maxLengthDiscount"
                        v-model="accountForm.discount"/>
                    </div>
                    <div class="col-md-2">{{$t('pay.dateExpirationDiscount')}}</div>
                    <div class="col-md-4">
                      <Datepicker
                        style="
                        --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                        --vdp-selected-bg-color: #436f98; text-align: center; max-width: 100%"
                        inputFormat='dd/MM/yyyy'
                        :lowerLimit="nowDate"
                        :locale="getLanguage()"
                        :typeable="false"
                        v-model="accountForm.expirationDiscount"
                      />
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-2">{{$t('pay.expirationDate')}}</div>
                    <div class="col-md-4">
                      <Datepicker
                        style="
                        --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
                        --vdp-selected-bg-color: #436f98; text-align: center; max-width: 100%"
                        inputFormat='dd/MM/yyyy'
                        :lowerLimit="nowDate"
                        :locale="getLanguage()"
                        :typeable="false"
                        v-model="accountForm.expirationFee"
                      />
                    </div>
                    <div class="col-md-1"></div>
                  </div>
                  <div class="row mb-1">
                    <div class="col pt-5 pb-4">{{$t('pay.excelColumns')}}</div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4 pt-2"><strong>{{$t('pay.unit')}}</strong></div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              :readonly="true"
                              :minlength="accountFormValidation.minLengthApartment"
                              :maxlength="accountFormValidation.maxLengthApartment"
                              v-model="accountForm.apartment"/>
                          </div>
                      </div>
                    </div>
                    <div
                      v-for="field in chargeAccountTypes" :key="field"
                      class="col-md-6">
                      <div class="row">
                        <div class="col-md-4 pt-2">{{field.name}}</div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              @change="changeTypeColumn(field.id, $event.target.value)"
                              :minlength="accountFormValidation.minLengthExcelCell"
                              :maxlength="accountFormValidation.maxLengthExcelCell"
                              v-model="field.columnInFile"/>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-4">
                    <div class="col">
                      <label>Excel</label>
                      <div>
                        <FileUploader
                          :maxNumFiles="1"
                          :maxFileSizeInMB="10"
                          :allowedFileTypes="['xls', 'xlsx']"
                          :sizeOfButton="{'height': '26px', 'width': '130px'}"
                          :modelValue="accountForm.files"
                          @update-modelValue="getFiles"
                        />
                      </div>
                    </div>
                  </div>
                </form>

                <div class="errorMessageValidation">
                  <p class="text-danger" v-if="v$.accountForm.$errors.length">
                    {{ v$.accountForm.$errors[0].$message }}
                  </p>
                </div>

                <div class="process_button text-center mb-4 " >
                  <div class="">
                    <Button
                      :text="$t('buttons.createPayAccount')"
                      @clicked="goToPreview"
                      color="primary"
                      class="d-inline"
                    />
                  </div>
                  <div v-if="help"
                    style="color: #436f98"
                    class="upper-left-label"
                    :data-tooltip="popUpHelpText"
                    data-position="bottom left"
                    data-variation="small wide"
                  >
                    <i class="question circle outline icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </SuiModalDescription>
      </div>
    </SuiModalContent>
  </SuiModal>

  <ChargeAccountExcelReview
    :show="showReviewModal"
    :dataAccountForm="dataAccount"
    @close="closeReview"
    @onSave="close"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription
} from 'vue-fomantic-ui'
import Datepicker from 'vue3-datepicker'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, numeric, minLength, maxLength } from '@vuelidate/validators/dist/raw.esm'
import Button from '../../components/form/Button'
import Select from '../../components/form/Select'
import FileUploader from '../../components/form/FileUploader'
import utilsHelpers from '../../utils/helpers'
import ChargeAccountExcelReview from './ChargeAccountExcelReview'
import { es, en } from 'date-fns/locale'
import i18n from '@/plugins/i18n'

export default {
  name: 'ChargeAccountForm',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Button,
    Select,
    FileUploader,
    Datepicker,
    ChargeAccountExcelReview
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    help: {
      type: Boolean,
      required: false,
      default: true
    },
    data: Object,
    ZIBIPayRules: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      years: [],
      months: [],
      nowDate: new Date(),
      showReview: false,
      accountForm: {
        year: null,
        month: null,
        discount: null,
        expirationDiscount: null,
        expirationFee: null,
        files: [],
        apartment: 'A',
        dynamicFields: []
      },
      accountFormValidation: {
        minLengthDiscount: 1,
        maxLengthDiscount: 2,
        minLengthExcelCell: 1,
        maxLengthExcelCell: 2
      }
    }
  },
  created () {
    this.init()
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    ...mapGetters('chargeAccount', ['chargeAccountTypes']),
    dialog () {
      return this.show
    },
    showReviewModal () {
      return this.showReview
    },
    dataAccount () {
      return this.accountForm
    },
    popUpHelpText () {
      const minAllowedAmount = this.formatNumberToColombianPesos(this.ZIBIPayRules.min_allowed_amount)
      const maxAllowedAmount = this.formatNumberToColombianPesos(this.ZIBIPayRules.max_allowed_amount)
      return `${this.$t('errors.pay.max')} ${minAllowedAmount} ${this.$t('errors.pay.min')} ${maxAllowedAmount}`
    }
  },
  validations () {
    return {
      accountForm: {
        year: {
          required: helpers.withMessage(this.$t('errors.pay.chooseYear'), required)
        },
        month: {
          required: helpers.withMessage(this.$t('errors.pay.chooseMonth'), required)
        },
        discount: {
          required: helpers.withMessage(this.$t('errors.pay.discountRequired'), required),
          numeric: helpers.withMessage(this.$t('errors.pay.validDiscountNumber'), numeric),
          minLengthValue: helpers.withMessage(`${this.$t('errors.pay.minDiscount')} ${this.accountFormValidation.minLengthDiscount} dígitos`, minLength(this.accountFormValidation.minLengthDiscount)),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.pay.maxDiscount')} ${this.accountFormValidation.maxLengthDiscount} dígitos`, maxLength(this.accountFormValidation.maxLengthDiscount))
        },
        expirationFee: {
          required: helpers.withMessage(this.$t('errors.pay.dateRequired'), required)
        },
        expirationDiscount: {
          required: helpers.withMessage(this.$t('errors.pay.dateDiscountRequired'), required)
        },
        apartment: {
          required: helpers.withMessage(this.$t('errors.pay.unitColumn'), required),
          alpha: helpers.withMessage(this.$t('errors.pay.onlyLetters'), utilsHelpers.validateFLNameFormat),
          minLengthValue: helpers.withMessage(`${this.$t('errors.pay.columnMin')} ${this.accountFormValidation.minLengthExcelCell} dígitos`, minLength(this.accountFormValidation.minLengthExcelCell)),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.pay.columnMax')} ${this.accountFormValidation.maxLengthExcelCell} dígitos`, maxLength(this.accountFormValidation.maxLengthExcelCell))
        },
        files: {
          required: helpers.withMessage(this.$t('errors.pay.chooseFile'), required)
        }
      }
    }
  },
  methods: {
    ...mapActions('chargeAccount', ['loadChargeAccountsTypes']),
    init () {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth() + 1
      this.accountForm.year = {
        value: currentYear,
        text: String(currentYear)
      }
      this.accountForm.month = {
        value: currentMonth,
        text: utilsHelpers.capitalize(this.$t(`months.${utilsHelpers.getMonthName(currentMonth)}`))
      }
      this.years = [0, 1, 2].map(idx => ({
        id: currentYear + idx,
        description: String(currentYear + idx)
      }))
      this.months = [...Array(12)].map((_, idx) => ({
        id: idx + 1,
        description: utilsHelpers.capitalize(this.$t(`months.${utilsHelpers.getMonthName(idx + 1)}`))
      }))

      if (this.chargeAccountTypes.length === 0) {
        this.loadChargeAccountsTypes()
      }
    },
    getLanguage () {
      if (i18n.global.locale === 'en') {
        return en
      } else {
        return es
      }
    },

    getFiles (files) {
      this.accountForm.files = files
    },

    changeTypeColumn (idType, value) {
      const field = this.accountForm.dynamicFields.find(field => field.id === idType)
      if (field) {
        field.value = value
      } else {
        this.accountForm.dynamicFields.push({ id: idType, value })
      }
    },

    validateForm () {
      this.v$.accountForm.$touch()
      let isFormValid = !(this.v$.accountForm.$error)

      if (isFormValid) {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), 1)
        const dateSelected = new Date(this.accountForm.year.value, this.accountForm.month.value - 1, 1)
        if (dateSelected < today) {
          this.$swal(this.$t('errors.pay.lastMonth'), '', 'warning')
          isFormValid = false
        }
      }

      return isFormValid
    },

    getFullExpirationDate (date) {
      const fullExpDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59
      )
      return fullExpDate
    },

    goToPreview () {
      if (!this.validateForm()) {
        return false
      }
      // console.log('expirationDiscount prev --->', this.accountForm.expirationDiscount)
      this.accountForm.expirationDiscount = this.getFullExpirationDate(this.accountForm.expirationDiscount)
      // console.log('expirationDiscount post --->', this.accountForm.expirationDiscount)
      // console.log('expirationFee prev --->', this.accountForm.expirationFee)
      this.accountForm.expirationFee = this.getFullExpirationDate(this.accountForm.expirationFee)
      // console.log('expirationFee post --->', this.accountForm.expirationFee)
      this.showReview = true
    },

    clearForm () {
      this.init()
      this.accountForm.discount = null
      this.accountForm.expirationDiscount = null
      this.accountForm.expirationFee = null
      this.accountForm.files = []
      this.accountForm.apartment = 'A'
      // this.accountForm.dynamicFields = []
      this.v$.$reset()
    },

    close () {
      this.clearForm()
      this.$emit('close')
    },

    closeReview () {
      this.showReview = false
    },

    formatNumberToColombianPesos (num) {
      return `$${Number(parseFloat(num).toFixed(2)).toLocaleString('es-CO', {
        minimumFractionDigits: 2
      })}`
    }
  }
}
</script>

<style scoped>
.process_button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
.modal-container {
    background-color: #fff;
    color: black;
    width: 655px;
    border-radius: 20px;
    padding: 20px;
    padding-top: 10px;
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    z-index: 99;
  }

  .errorMessageValidation {
    width:100%;
    height: 30px;
    margin-bottom: 5px;
    align-self:center;
    display: flex;
    justify-content:center;
  }
</style>
