<template>
  <div class="justify-content-start h-100 view-container">
    <HeaderResidential />
    <div class="mt-4">
      <div class="row">
        <div class="col-md-4 pt-2">
          <Button
            :text="$t('buttons.createPayAccount')"
            color="primary"
            class="d-inline"
            @clicked="newChargeAccount"
          />
        </div>
      </div>

      <div class="mt-3 mb-5" style="margin-left: 12px; margin-right: 13px">
        <div class="row">
          <table class="ui blue unstackable selectable celled table" style="cursor: default !important; color: #808080">
            <thead>
            <tr>
              <th class="text-center">{{$t('pay.year')}}</th>
              <th class="text-center">{{$t('pay.month')}}</th>
              <th class="text-center">{{$t('pay.discount')}}</th>
              <th class="text-center">{{$t('pay.dateExpirationDiscount')}}</th>
              <th class="text-center">{{$t('pay.expirationDate')}}</th>
              <th class="text-center">{{$t('pay.createdAt')}}</th>
              <th class="text-center">{{$t('pay.state')}}</th>
              <th class="text-center">{{$t('pay.download')}}</th>
            </tr>
            </thead>
            <tbody v-if="chargeAccountsData.length === 0">
              <tr>
                <td colspan="8" class="text-center">
                  No se encontraron cuentas de cobro
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="account in chargeAccountsData"
                :key="account"
              >
                <td @click="openExportModal(account)" class="text-center">{{ account.year }}</td>
                <td @click="openExportModal(account)" class="text-center">{{ getMonthName(account.month) }}</td>
                <td @click="openExportModal(account)" class="text-center">{{ `${account.discount} %` }}</td>
                <!-- <td @click="openExportModal(account)" class="text-center">{{ formatDateLocal(account.expirationDiscount) }}</td>
                <td @click="openExportModal(account)" class="text-center">{{ formatDateLocal(account.expirationFee) }}</td> -->
                <td @click="openExportModal(account)" class="text-center">{{ getVisualFormattedDate(account.expirationDiscount) }}</td>
                <td @click="openExportModal(account)" class="text-center">{{ getVisualFormattedDate(account.expirationFee) }}</td>
                <td @click="openExportModal(account)" class="text-center">{{ formatDateServer(account.createdAt) }}</td>
                <td style="text-align: center">
                  <div class="ui toggle checkbox text-center">
                    <input
                      type="checkbox"
                      :checked="account.status === status.ACTIVE"
                      @change="changeStatus(account.id, $event)"
                    >
                    <label style="margin-left: -6px"></label>
                  </div>
                  <br>
                  <small>{{ $t(`status.${account.status}`) }}</small>
                </td>
                <td style="text-align: center">
                  <a href="javascript: void()" @click="openExportModal(account)">
                    <i class="download icon" style="font-size: 20px"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <ChargeAccountForm
    :show="showAccountForm"
    :data="accountForm"
    :ZIBIPayRules="ZIBIPayRules"
    @close="closeForm"
  />

  <ChargeAccountsExportModal
    :show="isExportingModal"
    :month="monthToExport"
    :year="yearToExport"
    :chargeAccountId="chargeAccountId"
    @close="closeExportModal"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HeaderResidential from '../../components/ui/HeaderResidential'
import Button from '../../components/form/Button'
import ChargeAccountForm from './ChargeAccountForm'
import helpers from '../../utils/helpers'
import ChargeAccountsExportModal from '@/components/modals/ChargeAccountsExportModal'

export default {
  name: 'ChargeAccount',
  components: {
    HeaderResidential,
    ChargeAccountForm,
    Button,
    ChargeAccountsExportModal
  },
  data () {
    return {
      showForm: false,
      accountEdition: {},
      isExporting: false,
      monthSelected: '',
      yearSelected: '',
      chargeAccountIdSelected: 0
    }
  },
  mounted () {
    this.load_ZIBIPay_rules()
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    ...mapGetters('chargeAccount', ['chargeAccounts']),
    ...mapGetters('payment', ['ZIBIPayRules']),
    showAccountForm () {
      return this.showForm
    },
    chargeAccountsData () {
      return this.chargeAccounts
    },
    residentForm () {
      return this.residentEdition
    },
    isExportingModal () {
      return this.isExporting
    },
    monthToExport () {
      return this.monthSelected
    },
    yearToExport () {
      return this.yearSelected
    },
    chargeAccountId () {
      return this.chargeAccountIdSelected
    }
  },
  created () {
    if (this.chargeAccounts.length === 0) {
      this.loadChargeAccounts(this.activeResidential.residentialId)
    }
  },
  methods: {
    ...mapActions('chargeAccount', ['loadChargeAccounts', 'changeStatusChargeAccount']),
    ...mapActions('payment', ['load_ZIBIPay_rules']),

    // formatDateLocal (date) {
    //   return helpers.formatDateDDMMYYLocal(date)
    // },

    getVisualFormattedDate (strdDate) {
      // 2023-03-02T23:59:00.000
      const [fullDate] = strdDate.split('T')
      const [year, month, day] = fullDate.split('-').map(strTime => Number(strTime))
      const date = new Date(year, month - 1, day)
      if (date) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return `${day < 9 ? `0${day}` : `${day}`}/${month < 9 ? `0${month}` : `${month}`}/${year}`
      }
      return ''
    },

    formatDateServer (date) {
      return helpers.formatDateDDMMYYServer(date)
    },

    getMonthName (month) {
      return helpers.capitalize(this.$t(`months.${helpers.getMonthName(month)}`))
    },

    newChargeAccount () {
      this.accountEdition = {}
      this.showForm = true
    },

    changeStatus (id, e) {
      const isActive = e.target.checked
      this.changeStatusChargeAccount({ id, isActive })
        .then(() => {})
        .catch((err) => {
          if (err.status === 400) {
            this.$swal('¡Opsss! Solo puede existir una notificación de cobro activa al mes, para crear una segunda notificación de cobro inactiva la que tienes actualmente activa.', '', 'warning')
          } else {
            this.$swal(this.$t('errors.somethingHappened'), '', 'error')
          }
          e.target.checked = false
          console.log(err.response)
        })
        .finally(() => {
          this.setLoading(false)
        })
    },

    openExportModal (account) {
      this.monthSelected = account.month
      this.yearSelected = account.year
      this.chargeAccountIdSelected = account.id
      this.isExporting = true
    },

    closeExportModal () {
      this.isExporting = false
    },

    closeForm () {
      this.showForm = false
    }
  }
}
</script>

<style scoped>
  .ui.table {
    background: transparent;
  }

  .ui.table > thead > tr > th {
    color: #808080
  }

  .ui.image.header > .content {
    font-size: 14px;
    font-weight: normal;
  }
</style>
