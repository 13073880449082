<template>
  <SuiModal v-model="dialog" >
    <SuiModalContent id="modalQRPreview" style="display: flex; flex-direction:column">
      <a>
        <i
        @click="close()"
        style="text-decoration:none !important; cursor: pointer;font-size: 25px;
          position: sticky; left: 105%; top: 10px; margin-right: -5px; margin-top: -5px;"
        class="times icon general-blue-color">
        </i>
      </a>
        <SuiModalDescription style="width: 100%">
            <div class="qr-container">
              <div id="qr-container">
                <!-- <img src="@/assets/img/qr.jpeg" style="width: 400px; height: 400px" /> -->
                <QrcodeVue
                  :value="code"
                  :size="400"
                  render-as="svg"
                  level="H"
                  foreground="#436F98"
                />
              </div>
              <Button
                style="align-self:center; margin-top:15px !important"
                color="primary"
                :text="$t('buttons.print')"
                @clicked="print"
              />
            </div>
        </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex'
import Button from '@/components/form/Button'

export default {
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    QrcodeVue,
    Button
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    dialog () {
      return this.show
    },
    code () {
      return this.activeResidential.qrLink
    }
  },
  methods: {
    print () {
      const prtContent = document.getElementById('qr-container')
      const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
      const html = `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8" />
            <title>LIZI - ${this.$t('qrCode')} ${this.activeResidential.name}</title>
          </head>  
          <body>
            <div style="text-align:center; width: 100%">
              <!--<img src="https://zetstorage.blob.core.windows.net/avatar/logo_zet.png" style="width: 150px; height: 150px"/><br>-->
              <h1 style="font-family: Arial">${this.$t('scanToRegister')}</h1><br>
              ${prtContent.innerHTML}
            </div>
          </body>
        </html>`
      winPrint.document.write(html)
      winPrint.document.close()
      setTimeout(() => {
        winPrint.print()
        winPrint.close()
      }, 500)
    },

    close () {
      this.$emit('close')
    }
  },
  mounted () {
    document.getElementById('modalQRPreview').parentElement.style.setProperty('width', 'auto', 'important')
    document.getElementById('modalQRPreview').parentElement.style.setProperty('max-width', '1100px', 'important')
  }
}
</script>

<style scoped>
  .qr-container {
    display: flex;
    justify-content: center;
    max-height: 400px;
    flex-direction: column;
    margin-top: 29px;
    margin-bottom: 20px;
  }
</style>
